<template>
  <div class="version__history-listing">
    <div
      v-if="!entryId"
      class="notifications__loading-section mb-1"
    >
      <b-spinner variant="primary" />
    </div>

    <div v-else-if="!Object.keys(allVersions).length">
      <p class="d-flex justify-content-center">
        {{ $t('entries.versions.no-versions') }}
      </p>
    </div>

    <template v-else-if="Object.entries(allVersions).length">
      <div class="version__history-listing-container">
        <div
          v-for="([month, value], index) in Object.entries(allVersions)"
          :key="month.id"
        >
          <p
            class="version__history-listing-month"
            :class="`version__history-listing-month-${index}`"
          >
            {{ month }}
          </p>
          <Version
            v-for="version in value"
            :key="version.id"
            :version="version"
            :entry-version="entryVersion"
            :model="model"
            :last-published-version-id="lastPublishedVersionId"
            :revision-id="revisionId"
            :comparing-id="comparingId"
            :site-id="siteId"
            :entry="entry"
            :show-logs="showLogs"
            :scheduled-versions="scheduledVersions"
            @handleVersionFetch="handleVersionFetch(version)"
            @fetchVersions="$emit('fetchVersions')"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { cloneDeep, groupBy, orderBy } from 'lodash'
import moment from 'moment'
import { ENTRY_STATUSES, readEntryVersion } from '@/codex-sdk/entries'
import Version from './Version.vue'

export default {
  name: 'Listing',
  components: {
    Version,
  },
  props: {
    versions: Array,
    entryVersion: Object,
    comparingId: String,
    model: Object,
    evId: String,
    entry: Object,
    siteId: String,
    showLogs: Boolean,
    scheduledVersions: Array,
  },
  computed: {
    revisionId() {
      return this.entry.system.revisionId
    },
    entryId() {
      return this.entry.id
    },
    sortedVersions() {
      const data = cloneDeep(this.versions)
      const ignoreChanges = ['UnpublishedAt', 'FirstPublishedAt']
      data.forEach(v => {
        if (v.activityLogs) {
          v.activityLogs = v.activityLogs.filter(a => !ignoreChanges.includes(a.fieldAlias))
        } else {
          v.activityLogs = []
        }
      })
      return orderBy(data, ['createdAt'], ['desc'])
    },
    allVersions() {
      return groupBy(this.sortedVersions, obj => moment(obj.createdAt).format('MMMM YYYY'))
    },
    lastPublishedVersionId() {
      if (this.entry.system.status === ENTRY_STATUSES.PUBLISHED || this.entry.system.status === ENTRY_STATUSES.EDITED) {
        return this.sortedVersions.find(v => v.entity.system.status === ENTRY_STATUSES.PUBLISHED)?.id
      }
      return null
    },
  },
  watch: {
    entryId: {
      handler() {
        this.checkQueryParam()
      },
      immediate: true,
    },
  },
  methods: {
    async handleVersionFetch(version) {
      try {
        const { data } = await readEntryVersion({
          entryId: this.entryId,
          versionId: version.id,
          modelAlias: this.model.alias,
          siteId: this.siteId,
        })
        this.$emit('handleVersionPreview', data)
      } catch (e) {
        console.log(e)
      }
    },

    handleCancelPreview() {
      this.handleVersionFetch({ id: this.entryVersion.currentVersionId })
    },

    checkQueryParam() {
      if (this.evId) {
        this.handleVersionFetch({ id: this.evId })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.version__history-listing {
  height: calc(100% - 35px);
}

.version__history-listing-container {
  height: 100%;
  overflow-y: auto;
}
</style>
